<div mat-dialog-content>
    <span class="title">{{'Filters' | translate}}</span>
    <div fxLayout="column" fxLayoutGap="1vh" class="filters">
        <div fxFlex *ngIf="data.isAdmin">
            <mat-form-field>
                <mat-label>{{'Agency' | translate}}</mat-label>
                <mat-select multiple [(ngModel)]="selected.agency">
                    <mat-option [value]="'All Agencies'" (click)="toggleSelectAll('agency', 'All Agencies')"
                        *ngIf="data.filters.agency?.length">
                        All Agencies
                    </mat-option>
                    <mat-option disabled class="no-data" value="" *ngIf="!data.filters.agency?.length">No
                        Data</mat-option>
                    <mat-option *ngFor="let agency of data.filters.agency" [value]="agency"
                        (click)="onSelectionChange('agency', agency, 'All Agencies')">
                        {{ agency }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex *ngIf="data.isAdmin">
            <mat-form-field>
                <mat-label>{{'Agent' | translate}}</mat-label>
                <mat-select [(ngModel)]="selected.agent" multiple>
                    <mat-option (click)="toggleSelectAll('agent', 'All Agents')" [value]="'All Agents'"
                        *ngIf="data.filters.agent?.length">
                        All Agents
                    </mat-option>
                    <mat-option disabled class="no-data" value="" *ngIf="!data.filters.agent?.length">No
                        Data</mat-option>
                    <mat-option *ngFor="let agent of data.filters.agent" [value]="agent"
                        (click)="onSelectionChange('agent', agent, 'All Agents')">{{ agent }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex>
            <mat-form-field>
                <mat-label>{{'Carrier' | translate}}</mat-label>
                <mat-select [(ngModel)]="selected.carrier" multiple>
                    <mat-option (click)="toggleSelectAll('carrier', 'All Carriers')" [value]="'All Carriers'"
                        *ngIf="data.filters.carrier?.length">
                        All Carriers
                    </mat-option>
                    <mat-option disabled class="no-data" value="" *ngIf="!data.filters.carrier?.length">No
                        Data</mat-option>
                    <mat-option *ngFor="let carrier of data.filters.carrier" [value]="carrier"
                        (click)="onSelectionChange('carrier', carrier, 'All Carriers')">{{ carrier
                        }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex>
            <mat-form-field>
                <mat-label>{{'State' | translate}}</mat-label>
                <mat-select [(ngModel)]="selected.state" multiple>
                    <mat-option (click)="toggleSelectAll('state', 'All States')" [value]="'All States'"
                        *ngIf="data.filters.state?.length">
                        All States
                    </mat-option>
                    <mat-option disabled class="no-data" value="" *ngIf="!data.filters.state?.length">No
                        Data</mat-option>
                    <mat-option *ngFor="let state of data.filters.state" [value]="state"
                        (click)="onSelectionChange('state', state, 'All States')">{{
                        state
                        }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex>
            <mat-form-field>
                <mat-label>{{'Product Line' | translate}}</mat-label>
                <mat-select [(ngModel)]="selected.productline" multiple>
                    <mat-option (click)="toggleSelectAll('productline', 'All Product Lines')"
                        [value]="'All Product Lines'" *ngIf="data.filters.productline?.length">
                        All Product Lines
                    </mat-option>
                    <mat-option disabled class="no-data" value="" *ngIf="!data.filters.productline?.length">No
                        Data</mat-option>
                    <mat-option *ngFor="let line of data.filters.productline" [value]="line"
                        (click)="onSelectionChange('productline', line, 'All Product Lines')">{{
                        line
                        }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex>
            <mat-form-field>
                <mat-label>{{'Line of Business' | translate}}</mat-label>
                <mat-select [(ngModel)]="selected.lob" multiple>
                    <mat-option (click)="toggleSelectAll('lob', 'All LOBs')" [value]="'All LOBs'"
                        *ngIf="data.filters.lob?.length">
                        All LOBs
                    </mat-option>
                    <mat-option disabled class="no-data" value="" *ngIf="!data.filters.lob?.length">No Data</mat-option>
                    <mat-option *ngFor="let lob of data.filters.lob" [value]="lob"
                        (click)="onSelectionChange('lob', lob, 'All LOBs')">{{
                        lob
                        }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex>
            <mat-form-field>
                <mat-label>{{'Date' | translate}}</mat-label>
                <mat-select [formControl]="dateRangeControl">
                    <mat-option value="R12">{{'Rolling 12 (R12)' | translate}}</mat-option>
                    <mat-option value="YTD">{{'Year to Date (YTD)' | translate}}</mat-option>
                    <mat-option value="LYTD">{{'Last Year to Date (LYTD)' | translate}}</mat-option>
                    <mat-option value="CUSTOM">{{'Custom Date Range' | translate}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxFlex [hidden]="dateRangeControl.value !== 'CUSTOM'">
            <mat-form-field>
                <mat-label>{{'Enter a date range' | translate}}</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate [formControl]="startDate" [value]="date" placeholder="Start date" />
                    <input matEndDate [formControl]="endDate" [value]="date" placeholder="End date" />
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
        </div>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>{{'Cancel' | translate}}</button>
    <button mat-raised-button color="primary" (click)="applyFilters()"
        [class.disabled]="startDate.invalid || endDate.invalid"
        [disabled]="startDate.invalid || endDate.invalid">{{'Apply' |
        translate}}</button>
</div>