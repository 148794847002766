import { Component, OnInit, ChangeDetectorRef, TemplateRef, OnDestroy, ViewChild, ElementRef } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { AlertActionEntityDialogComponent, LoaderService, RequestService } from "src/app/shared";
import { capitalizeFirstLetter, getCSSVariableValue } from "src/app/shared/helpers/functions";
import { MondayService } from "src/app/shared/services/monday.service";
import { PGIService } from "src/app/shared/services/pgi.service";
import { ChartsFilterDialogComponent } from "./charts-filter-dialog/charts-filter-dialog.component";
import { ChartComponent } from "ng-apexcharts";
import moment from "moment";
import { CurrencyPipe } from "@angular/common";
import { MatSelect } from "@angular/material/select";

@Component({
    selector: 'app-charts',
    templateUrl: './charts.component.html',
    styleUrl: './charts.component.scss',
    providers: [CurrencyPipe],
})
export class ChartsPageComponent implements OnInit, OnDestroy {
    // agancies: any = [];
    // products: any = [];
    // lobs: any = [];
    // agents: any = [];
    // states: any = [];
    // carriers: any = [];
    chart: any = {};
    baseColor = getCSSVariableValue('--bs-primary');
    secondaryColor = getCSSVariableValue('--bs-second-primary');
    thirdColor = getCSSVariableValue('--bs-third-primary');
    fourColor = getCSSVariableValue('--bs-four-primary');
    labelColor = getCSSVariableValue('--bs-gray-500');
    borderColor = getCSSVariableValue('--bs-gray-200');
    today = new Date();
    currentDate = this.today.toISOString().split('T')[0];
    currentTime = this.today.toTimeString().split(' ')[0];
    currentDateTime = `${this.currentDate.replace(/-/g, '_')}_${this.currentTime.replace(/_/g, '')}`;
    writePremiumChartTitle: string = 'Written Premium by Carrier [TOP 10]';
    pilChartTitle: string = 'PIF by Carrier [TOP 10]';
    form: any = {};
    selectTab: any = 'Auto';
    openDialog = false;
    DashboardTitle: any = 'Dashboard';
    ChartData: any = {};
    TablesData: any = {};
    filters: any = {};
    activeFilters: any = undefined;
    defaultActiveFilters: any = {
        agency: ['All Agencies'],
        lob: ['All LOBs'],
        state: ['All States'],
        productline: ['All Product Lines'],
        agent: ['All Agents'],
        carrier: ['All Carriers'],
    };
    selectedPage: string = 'overview';
    detailsData: any = undefined;
    writtenPremiumChart: any = undefined;
    policiesInForceChart: any = undefined;
    growthChart: any = undefined;
    quotesChart: any = undefined;
    isAdmin: boolean = false;
    selectedPoliciesInForceFilter: string = 'personal';
    policiesInForceOptions = [{ name: "PL", value: "personal" }, { name: "CL", value: "Commercial" }, { name: "PL (L/F)", value: "Other PL (Life/Financial)" }, { name: "CL (H,B)", value: "Other CL (Health/Benefits)" }, { name: "Total", value: "All" }];
    selectedWrittenPremiumFilter: string = 'personal';
    selectedWrittenPremiumCarrierFilter: string = 'carrier';
    writtenPremiumOptions = [{ name: "PL", value: "personal" }, { name: "CL", value: "Commercial" }, { name: "PL (L/F)", value: "Other PL (Life/Financial)" }, { name: "CL (H,B)", value: "Other CL (Health/Benefits)" }, { name: "Total", value: "all" }];
    selectedGrowthFilter: string = 'wp';
    growthOptions = [{ name: "WP", value: "wp" }, { name: "PIF", value: "PIF" }];
    quotesOptions = [{ name: "PL", value: "personalLines" }];
    selectedQuoteFilter: string = 'personalLines';
    topTableExpanded: boolean = false;
    growthTableExpanded: boolean = false;
    carrierOptions: any = [{ name: "Carrier", value: "carrier" }, { name: "LOB", value: "lob" }, { name: 'City', value: 'city' }, { name: 'State', value: 'state' }, { name: 'Transaction Type', value: 'Transaction Type' }];
    selectedPILCarrierFilter: string = 'carrier';
    chips: any = [];
    public showFilters: boolean = false;

    private currentUser: any = undefined;
    private subscriptions: any = [];
    private pgiData: any = {};
    private email: string = null;

    @ViewChild('writtenPremiumChartSelector', { static: false }) writtenPremiumChartSelector: ChartComponent;
    @ViewChild('policiesInForceSelector', { static: false }) policiesInForceSelector: ChartComponent;
    @ViewChild('growthChartSelector', { static: false }) growthChartSelector: ChartComponent;
    @ViewChild('quotesChartSelector', { static: false }) quotesChartSelector: ChartComponent;
    @ViewChild('chartsDropdown') chartsDropdown: MatSelect;

    constructor(private dialog: MatDialog, public requestService: RequestService,
        private router: Router, private currencyPipe: CurrencyPipe,
        private cdr: ChangeDetectorRef,
        private mondayService: MondayService,
        private pgiService: PGIService,
        public route: ActivatedRoute, private loaderService: LoaderService
    ) { }

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            console.log(params);
            if (params['token']) {
                let token = params['token'];
                this.email = this.decodeToken(token)?.emails[0];
                // for testing
                this.email = 'jaclyn.schmitz@brightsideins.com';
                this.pgiDataInit(this.email);
            } else {
                this.pgiDataInit(null);
            }
        });

        this.subscriptions.push(this.requestService.currentUserSubject.subscribe((data: any) => {
            if (data) {
                this.currentUser = data;
                this.isAdmin = this.requestService.isUserRoleAdmin();
            }
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    decodeToken(token: any) {
        const payload = token.split('.')[1];
        const decoded = atob(payload);
        return JSON.parse(decoded);
    }
    async pgiDataInit(email: any) {
        this.loaderService.display(true);
        if (email) {
            // localStorage.setItem('azure_email', email);
            let CRMuser = await this.mondayService.getAgentByEmail(email);
            if (CRMuser) {
                // let userType = CRMuser['dup__of_type'];
                let newUserType = CRMuser['dup__of_type'];
                // localStorage.setItem('monday_user_type', userType);
                localStorage.setItem('CRMuser', JSON.stringify(CRMuser));
                // let newUserType = localStorage.getItem('monday_user_type');

                if (this.requestService.isUserAgent()) {
                    newUserType = 'Agency Admin';
                    CRMuser.type = '1';
                } else {
                    newUserType = 'Agent';
                    CRMuser.type = '2';
                }

                CRMuser.newUserType = newUserType;

                this.requestService.currentUser = CRMuser;
                this.requestService.currentUserSubject.next(CRMuser);

            } else {
                console.log('User not found');
            }
        }
        // this.getDropDownFilter();
        // this.onSearch();
        this.cdr.detectChanges();
        if (this.requestService.currentUser?.newUserType == 'PGI-Admin') {
            this.DashboardTitle = 'PGI Admin';
        } else if (this.requestService.currentUser?.newUserType == 'Agency Admin') {
            this.DashboardTitle = this.requestService.currentUser?.link_to_agencies5;
        } else if (this.requestService.currentUser?.newUserType == 'Agent') {
            this.DashboardTitle =
                this.requestService.currentUser?.link_to_agencies5 + ' - ' + this.requestService.currentUser?.name;
        }

        // this.loadingService.show();
        let pgiFiltersData = await this.pgiService.getPGIOverviewFilter();
        if (pgiFiltersData && pgiFiltersData.filters) {
            this.filters = {
                agency: pgiFiltersData.filters.Agency,
                lob: pgiFiltersData.filters.LOB,
                state: pgiFiltersData.filters.AgencyState,
                productline: pgiFiltersData.filters.ProductLine,
                agent: pgiFiltersData.filters.Agent,
                carrier: pgiFiltersData.filters.CarrierName,
            };
        }
        this.pgiData = await this.pgiService.getPGIOverview(this.activeFilters);
        // this.pgiService.getPgi().subscribe(data => {
        //     this.pgiData = data;
        this.dataMapping(this.pgiData);
        this.loaderService.display(false);
        // });

        // this.loadingService.hide();
    }
    dataMapping(data: any) {
        const fixedOrder = ['Personal', 'Commercial', 'Others', 'Other PL (Life/Financial)', 'Other CL (Health/Benefits)', 'Total'];

        // Top Table
        this.TablesData['topData'] = data.top?.map((item: any) => {
            return {
                type: item.type,
                value: item.value?.toFixed(2),
                growth: item.growth.toFixed(2),
                count: item.count?.toFixed(2),
                growth_percentage: item.growth_percentage.toFixed(2),
                value2: item.value2?.toFixed(2),
                growth2: item.growth2.toFixed(2),
                count2: item.count2?.toFixed(2),
                growth_percentage2: item.growth_percentage2.toFixed(2),
                expandable: item.group == 'Other' && item.type != 'Others' ? true : false,
                triggerExpand: item.type == 'Others' ? true : false,
            };
        }).sort((a, b) => fixedOrder.indexOf(a.type) - fixedOrder.indexOf(b.type));

        try {
            this.setWrittenPremiumChart(this.selectedWrittenPremiumFilter, this.selectedWrittenPremiumCarrierFilter, true);
            this.setPoliciesInForceChart(this.selectedPoliciesInForceFilter, this.selectedPILCarrierFilter, true);
            this.setGrowthChart(this.selectedGrowthFilter, true);
            this.setQuoteChart(this.selectedQuoteFilter, true);
        }
        catch (e) {
            console.error(e);
            this.loaderService.display(false);
        }
    }

    formatMonth(date: string): string {
        const year = date.substring(0, 4);
        const month = date.substring(4);
        const monthInLetters = new Date(`${year}-${month}-01`).toLocaleString(
            'default',
            {
                month: 'short',
            }
        );
        return monthInLetters.substring(0, 3);
    }
    OnSubmit() { }

    goMap() {
        this.router.navigateByUrl('/map');
    }
    open() {
        if (this.selectedPage != 'targeted_opportunities' && !this.detailsData) {
            const dialog = this.dialog.open(ChartsFilterDialogComponent, {
                width: 'fit-content',
                minWidth: '40vw',
                disableClose: true,
                data: {
                    loggedInUserType: this.requestService.currentUser?.newUserType,
                    filters: this.filters,
                    activeFilters: this.activeFilters || this.defaultActiveFilters,
                    isAdmin: this.isAdmin,
                }
            });
            dialog.afterClosed().subscribe((data: any) => {
                if (data) {
                    this.activeFilters = data;
                    this.getChips();
                    // console.log(this.activeFilters)
                    // console.log(this.chips)
                }
            });
        }
    }

    refreshFilter() {
        this.chips = [];
        this.activeFilters = this.defaultActiveFilters;
        this.detailsData = undefined;
        this.selectedPage = 'overview';
        this.pgiDataInit(this.email);
    }

    welcomeFilter() {
        this.openDialog = false;
    }
    detectChanges() {
        this.cdr.detectChanges();
    }
    toggleDetails(row: any) {
        console.log('row', row);
        const detailsRows = document.querySelectorAll('.details');
        detailsRows.forEach((row) => {
            row.classList.toggle('hidden');
        });
    }
    toggleDetails2(row: any) {
        console.log('row', row);
        const detailsRows = document.querySelectorAll('.details2');
        detailsRows.forEach((row) => {
            row.classList.toggle('hidden');
        });
    }

    changeTab(event: any) {
        this.chartsDropdown.close();
        this.selectedPage = event.value;
        this.detailsData = undefined;
    }

    viewDetails(details: any) {
        this.selectedPage = details.tab;
        this.detailsData = details;
    }

    goBack() {
        this.detailsData = undefined;
        this.selectedPage = 'targeted_opportunities';
    }

    private getPieChart(title: string = '', series = [], labels = this.ChartData.written_premium.labels, height = 350) {
        let filename = title?.replace(' ', '_').toLowerCase() + '_' + this.currentDateTime;

        return {
            series: series,
            labels: labels,
            chart: {
                fontFamily: 'inherit',
                type: 'pie',
                height: height,
                toolbar: {
                    show: true,
                    // offsetX: 10,
                    // offsetY: height - 50,
                    tools: {
                        download:
                            '  <i style="color: var(--bs-primary) !important; width: 30px; font-size: 17px; vertical-align: middle;" class="fa-solid fs-1 fa-circle-arrow-down p-1"></i>',
                    },
                    export: {
                        csv: {
                            filename: filename,
                        },
                        svg: {
                            filename: filename,
                        },
                        png: {
                            filename: filename,
                        },
                    },
                },
            },
            theme: {
                monochrome: {
                    enabled: true,
                },
            },

            legend: {
                show: true,
                position: 'bottom',
                horizontalAlign: 'left'
            },
            dataLabels: {
                distributed: true,
                enabled: true,
            },
            stroke: {
                show: false,
                // width: 3,
                // colors: [this.baseColor, this.secondaryColor, this.thirdColor, this.fourColor],
            },
            // title: {
            //     text: 'wefewf',
            //     align: 'left',
            // },
            fill: {
                opacity: 1,
            },

            tooltip: {
                style: {
                    fontSize: '12px',
                },
                y: {
                    formatter: (val: number) => {
                        return this.currencyPipe.transform(val, 'USD', 'symbol', '1.2-2');
                    },
                },
            },
            colors: ['#6ce8f3', '#41b8d6', '#298ab9', '#2f5f99', '#31356d', '#534d85', '#6e74b1', '#8f91d7', '#b1baf4', '#b2d4f9'],
        };
    }

    private getLineChart(title: string = '', series = [], labels = [], height = 350, isSlice = false, isUSD = false) {
        let filename = title?.replace(' ', '_').toLowerCase() + '_' + this.currentDateTime;

        return {
            series: series,
            chart: {
                fontFamily: 'inherit',
                type: 'line',
                stacked: false,
                height: height,
                toolbar: {
                    show: true,
                    // offsetX: 10,
                    // offsetY: height - 20,
                    tools: {
                        download:
                            '  <i style="color: var(--bs-primary) !important; width: 30px; font-size: 17px; vertical-align: middle;" class="fa-solid fs-1 fa-circle-arrow-down p-1"></i>',
                    },
                    export: {
                        csv: {
                            filename: filename,
                        },
                        svg: {
                            filename: filename,
                        },
                        png: {
                            filename: filename,
                        },
                    },
                },
            },

            xaxis: {
                categories: labels,
                labels: {
                    formatter: (value) => {
                        if (isSlice && value?.length > 3) {
                            return value.slice(0, 3);
                        }
                        return value;
                    }
                }
            },
            yaxis: {
                // min: 0,
                // max: 1000000,
                labels: {
                    formatter: (value: any) =>
                        value == 0
                            ? 0
                            : value.toLocaleString('en-US', { minimumIntegerDigits: 5 }),

                    style: {
                        colors: this.labelColor,
                        fontSize: '12px',
                    },
                },
            },

            legend: {
                show: true,
                position: 'bottom',
                horizontalAlign: 'left'
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 3,
                colors: [this.baseColor, this.secondaryColor, this.thirdColor, this.fourColor],
            },

            fill: {
                opacity: 1,
            },
            tooltip: {
                x: {
                    show: true,
                    formatter: function (val) {
                        // if (isSlice) {
                        //     return labels[val - 1]
                        // }
                        return labels[val - 1];
                    }
                },
                style: {
                    fontSize: '12px',
                },
                y: {
                    formatter: (val: number) => {
                        if (isUSD)
                            return this.currencyPipe.transform(val, 'USD', 'symbol', '1.2-2');
                        return val;
                    },
                },
            },
            colors: [this.baseColor, this.secondaryColor, this.thirdColor, this.fourColor],
            grid: {
                borderColor: this.borderColor,
                strokeDashArray: 4,
                yaxis: {
                    lines: {
                        show: true,
                    },
                },
            },
        };
    }

    isEmptyObject(obj: any): boolean {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
    }

    filterPolicesInForce(firstFilter = this.selectedPoliciesInForceFilter, secondFilter = this.selectedPILCarrierFilter) {
        this.setPoliciesInForceChart(firstFilter, secondFilter, false);
        this.policiesInForceSelector.updateOptions({
            series: this.ChartData['policies_in_force'].data,
            labels: this.ChartData['policies_in_force'].labels,
        });
        this.pilChartTitle = `PIF by ${capitalizeFirstLetter(secondFilter)} [TOP 10]`;
    }

    filterWrittenPremium(firstFilter = this.selectedWrittenPremiumFilter, secondFilter = this.selectedWrittenPremiumCarrierFilter) {
        this.setWrittenPremiumChart(firstFilter, secondFilter, false);
        this.writtenPremiumChartSelector.updateOptions({
            series: this.ChartData['written_premium'].data,
            labels: this.ChartData['written_premium'].labels,
        });
        this.writePremiumChartTitle = `Written Premium by ${capitalizeFirstLetter(secondFilter)} [TOP 10]`;
    }

    filterGrowth() {
        this.setGrowthChart(this.selectedGrowthFilter, false);
        this.growthChartSelector.updateOptions({
            series: this.ChartData['growth_chart'].data,
            labels: this.ChartData['growth_chart'].labels,
        });
    }

    filterQuotes(value) {
        this.setQuoteChart(value, false);
        this.quotesChartSelector.updateOptions({
            series: this.ChartData['quotes_chart'].data,
            labels: this.ChartData['quotes_chart'].labels,
        });
    }

    private setPoliciesInForceChart(firstFilter: string, secondFilter: string, renderChart: boolean = false) {
        let data = this.pgiData.policies_in_force.find(i => i.type.toLowerCase() == firstFilter.toLowerCase() && i.group.toLowerCase() == secondFilter.toLowerCase().replace(' ', ''))?.data;

        if (data) {
            this.TablesData['policies_in_force'] = data.map(
                (item: any) => {
                    return {
                        company: capitalizeFirstLetter(item.category),
                        value: item.value,
                        percent_of_total: item.percent_of_total?.toFixed(2),
                    };
                }
            );

            this.ChartData['policies_in_force'] = {
                data: data.map((item: any) => item.value),
                labels: data.map((item: any) => capitalizeFirstLetter(item.category)),
            };

            if (renderChart) {
                this.policiesInForceChart = this.getPieChart('Policies In Force', this.ChartData.policies_in_force.data, this.ChartData.policies_in_force.labels);
            }
        }
    }

    private setWrittenPremiumChart(firstFilter: string, secondFilter: string, renderChart: boolean = false) {
        let data = this.pgiData.written_premium.find(i => i.type.toLowerCase() == firstFilter.toLowerCase() && i.group.toLowerCase() == secondFilter.toLowerCase().replace(' ', ''))?.data;

        if (data) {
            this.TablesData['written_premium'] = data.map(
                (item: any) => {
                    return {
                        company: capitalizeFirstLetter(item.category),
                        value: item.value,
                        percent_of_total: item.percent_of_total?.toFixed(2),
                    };
                }
            );

            this.ChartData['written_premium'] = {
                data: data.map((item: any) => item.value),
                labels: data.map((item: any) => capitalizeFirstLetter(item.category)),
            };

            if (renderChart) {
                this.writtenPremiumChart = this.getPieChart('Written premium', this.ChartData.written_premium.data, this.ChartData.written_premium.labels);
            }
        }
    }

    private setGrowthChart(filter: string, renderChart: boolean = false) {
        const order = ["personalLines", "commercialLines", "other", "otherPL", "otherCL", "total"];
        this.TablesData['growth'] = this.pgiData.growth[filter.toUpperCase()]?.map((item: any) => {
            return {
                ...item,
                expandable: item.group == 'Other' && item.label.toLowerCase() != 'other' ? true : false,
                triggerExpand: item.group?.toLowerCase() == item.label?.toLowerCase(),
            };
        }).sort((a, b) => order.indexOf(a.label) - order.indexOf(b.label));
        // console.log(this.TablesData['growth'])
        // const firstOtherIndex = this.TablesData['growth'].findIndex((item: any) => item.group === 'Other');

        // if (firstOtherIndex !== -1) {
        //     // Insert the new 'Other' row before the first 'Other' group
        //     this.TablesData['growth'].splice(firstOtherIndex, 0, {
        //         label: 'Other',
        //         group: '',
        //         triggedExpand: true,
        //         expandable: false,
        //     });
        // }

        let dataChart = this.pgiData.growth_chart;
        if (dataChart) {
            let months = [];
            dataChart[0].data.forEach(data => {
                if (!months.includes(data.month)) {
                    months.push(moment(data.month, "YYYYMM").format("MMM YYYY"));
                }
            });

            this.ChartData['growth_chart'] = {
                data: dataChart.map((item: any) => {
                    return {
                        name: item.label,
                        data: item.data.map((item1: any) => filter == 'wp' ? item1.wp : item1.pif),
                    };
                }),
                labels: months,
            };

            if (renderChart) {
                this.growthChart = this.getLineChart('Growth', this.ChartData.growth_chart.data, this.ChartData.growth_chart.labels, 400, false, true);
            }
        }
    }

    private setQuoteChart(filter: string, renderChart: boolean = false) {
        let dataTable = this.pgiData.quotes;
        if (dataTable) {
            this.TablesData['quotes'] = dataTable[0];
        }

        let dataChart = this.pgiData.quotes_chart;
        if (dataChart) {
            let months = [];
            dataChart[0].data.forEach(data => {
                if (!months.includes(data.month)) {
                    months.push(moment(data.month, "YYYYMM").format("MMM YYYY"));
                }
            });
            // console.log(months)

            this.ChartData['quotes_chart'] = {
                data: dataChart.map((item: any) => {
                    return {
                        name: capitalizeFirstLetter(item.label.replace('_', ' ')),
                        data: item.data.map((item1: any) => item1.value),
                    };
                }),
                labels: months,
            };

            if (renderChart) {
                this.quotesChart = this.getLineChart('EZLynx Quotes to New Business Policies', this.ChartData.quotes_chart.data, this.ChartData.quotes_chart.labels, 400, false);
            }
        }
    }

    expandTopTable(item: any) {
        if (item.triggerExpand) {
            this.topTableExpanded = !this.topTableExpanded;
        }
    }

    expandGrowth(item: any) {
        if (item.triggerExpand) {
            this.growthTableExpanded = !this.growthTableExpanded;
        }
    }

    private getChips() {
        this.chips = [];
        Object.keys(this.activeFilters).forEach(key => {
            if (this.activeFilters[key]?.length) {
                if (Array.isArray(this.activeFilters[key])) {
                    this.activeFilters[key].forEach(filter => {
                        this.chips.push({ name: filter, value: filter, type: key });
                    });
                }
                else {
                    if (key == 'dateType') {
                        if (this.activeFilters['dateType'].toLowerCase() == 'custom') {
                            this.chips.push({ name: `Custom (${moment(this.activeFilters.startDate).format('YYYY-MM-DD')} to ${moment(this.activeFilters.endDate).format('YYYY-MM-DD')})`, value: this.activeFilters[key], type: key });
                        }
                        else {
                            this.chips.push({ name: this.activeFilters[key], value: this.activeFilters[key], type: key });
                        }
                    }
                }
            }
        });
    }

    removeFilter(filter: any) {
        if (Array.isArray(this.activeFilters[filter.type]))
            this.activeFilters[filter.type] = this.activeFilters[filter.type].filter(i => i != filter.value);
        else {
            this.activeFilters[filter.type] = '';

            if (filter.type == 'dateType') {
                this.activeFilters['startDate'] = '';
                this.activeFilters['endDate'] = '';
            }
        }

        this.getChips();
        this.pgiService.getPgi().subscribe(data => {
            this.pgiData = data;
            this.dataMapping(this.pgiData);
            this.loaderService.display(false);
        });
    }

    underConstruction() {
        const dialog = this.dialog.open(AlertActionEntityDialogComponent, {
            data: {
                description: 'This feature is under construction.',
                alertSetting: {
                    showCloseButton: true,
                    style: { 'color': '#34577f' },
                    closeButtonStyle: { 'color': 'white', 'background-color': '#34577f' },
                },
                disableClose: true,
                width: 'fit-content'
            }
        });
    }

    toggleMoreFilter() {
        this.showFilters = !this.showFilters;
    }
}
