<div class="filters">
    <div fxLayout="row" fxLayout.md="column" fxLayoutGap="1vw" fxLayoutAlign="space-between end">
        <div fxFlex fxLayoutAlign="start end" style="align-self:end" fxGrow="0">
            <div class="dashboard-title">{{ DashboardTitle }}</div>
        </div>
        <div fxFlex class="chips" fxLayoutAlign="end">
            <mat-chip-set fxLayout="row wrap" fxLayoutGap="0.5vw">
                @for (item of chips | slice:0:3; track $index) {
                <mat-chip fxFlex fxGrow="0">
                    <mat-icon leadingIcon class="remove-filter" (click)="removeFilter(item)">close</mat-icon>
                    {{item.name}}
                </mat-chip>
                }
                @if (chips.length > 3){
                <mat-chip class="more" fxFlex fxGrow="0" (click)="toggleMoreFilter()">
                    More
                </mat-chip>
                }
            </mat-chip-set>
        </div>
        <div fxFlex="15" fxLayoutAlign="end center" fxGrow="1">
            <mat-form-field class="filter">
                <!-- <mat-label>{{'Overview' | translate}}</mat-label> -->
                <mat-select #chartsDropdown [(ngModel)]="selectedPage" (selectionChange)="changeTab($event)">
                    <mat-option value="overview">Book Overview</mat-option>
                    <mat-option value="targeted_opportunities">Targeted Opportunities</mat-option>
                    <mat-option value="commissions">Commissions Overview</mat-option>
                    <mat-option value="outliers">Production Characteristics</mat-option>
                    <mat-option value="profitablity_rentention">Profitability & Retention</mat-option>
                    <mat-option value="commissions_modeling">Commissions Modeling</mat-option>
                    <mat-option value="operational_opportunities">Operational Opportunities</mat-option>
                    <mat-option value="licensing">Licensing</mat-option>
                    <mat-option value="development_resources">Business Development Resources</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxFlex fxGrow="0" fxLayoutGap="1vw" fxLayoutAlign="end">
            <div fxFlex fxGrow="0" *ngIf="selectedPage != 'targeted_opportunities' && !detailsData">
                <svg xmlns="http://www.w3.org/2000/svg" (click)="open()" [matTooltip]="'Filters' | translate"
                    height="40px" viewBox="0 -960 960 960" width="40px" fill="#33577F">
                    <path d="M400-240v-80h160v80H400ZM240-440v-80h480v80H240ZM120-640v-80h720v80H120Z" />
                </svg>
            </div>
            <div fxFlex fxGrow="0" *ngIf="!(selectedPage != 'targeted_opportunities' && !detailsData)">
                <svg xmlns="http://www.w3.org/2000/svg" (click)="open()" [matTooltip]="'Filters' | translate"
                    height="40px" style="cursor: no-drop;" viewBox="0 -960 960 960" width="40px" fill="grey">
                    <path d="M400-240v-80h160v80H400ZM240-440v-80h480v80H240ZM120-640v-80h720v80H120Z" />
                </svg>
            </div>
            <div fxFlex fxGrow="0">
                <svg fxFlex fxGrow="0" xmlns="http://www.w3.org/2000/svg" (click)="refreshFilter()"
                    [matTooltip]="'Refresh' | translate" class="mx-3" height="40px" viewBox="0 -960 960 960"
                    width="40px" fill="#33577F">
                    <path
                        d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-110h80v280H520v-80h168q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q77 0 139-44t87-116h84q-28 106-114 173t-196 67Z" />
                </svg>
            </div>
            <div fxFlex fxGrow="0" [hidden]="requestService.currentUser?.newUserType !== 'PGI-Admin'">
                <svg xmlns="http://www.w3.org/2000/svg" (click)="goMap()" [matTooltip]="'Map' | translate" height="40px"
                    viewBox="0 -960 960 960" width="40px" fill="#33577F">
                    <path
                        d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm-40-82v-78q-33 0-56.5-23.5T360-320v-40L168-552q-3 18-5.5 36t-2.5 36q0 121 79.5 212T440-162Zm276-102q20-22 36-47.5t26.5-53q10.5-27.5 16-56.5t5.5-59q0-98-54.5-179T600-776v16q0 33-23.5 56.5T520-680h-80v80q0 17-11.5 28.5T400-560h-80v80h240q17 0 28.5 11.5T600-440v120h40q26 0 47 15.5t29 40.5Z" />
                </svg>
            </div>
        </div>
    </div>
</div>
<ng-container *ngIf="selectedPage == 'targeted_opportunities' && !detailsData">
    <app-targeted-opportunities (detailsToView)="viewDetails($event)"></app-targeted-opportunities>
</ng-container>
<ng-container *ngIf="selectedPage == 'targeted_opportunities' && detailsData">
    <app-opportunity-details (goBackEmit)="goBack()" [detailsData]="detailsData"></app-opportunity-details>
</ng-container>
<ng-container *ngIf="selectedPage == 'overview'">
    <div class="table-top" *ngIf="TablesData?.topData">
        <table>
            <thead>
                <tr>
                    <th style="text-align: left;">Line of Business</th>
                    <th>Written Premium</th>
                    <th>WP Growth</th>
                    <th>Policies in Force</th>
                    <th>PIF Growth</th>
                    <th>New Business WP</th>
                    <th>NB WP Growth</th>
                    <th>NB PIF</th>
                    <th>NB PIF Growth</th>
                </tr>
            </thead>
            <tbody>
                @for (item of TablesData?.topData; track $index) {
                <tr [hidden]="!topTableExpanded && item.expandable" (click)="expandTopTable(item)"
                    [ngStyle]="{'cursor': item.triggerExpand ? 'pointer': 'default'}">
                    <td [ngStyle]="{'padding-left': item.expandable ? '1vw': ''}">{{ item.type }} <mat-icon
                            style="vertical-align: text-top;font-size: 13pt;"
                            *ngIf="item.triggerExpand">{{topTableExpanded?
                            'keyboard_arrow_up':'keyboard_arrow_down'}}</mat-icon></td>
                    <td>{{ item.value | currency : "USD" }}</td>
                    <td>
                        {{ item.growth | percent
                        }}<span *ngIf="item.growth > 0" style="color: green">&nbsp;&#9650;</span><span
                            *ngIf="item.growth < 0" style="color: red">&nbsp;&#9660;</span>
                    </td>
                    <td>{{ item.count | number }}</td>
                    <td>
                        {{ item.growth_percentage | percent
                        }}<span *ngIf="item.growth_percentage > 0" style="color: green">&nbsp;&#9650;</span><span
                            *ngIf="item.growth_percentage < 0" style="color: red">&nbsp;&#9660;</span>
                    </td>
                    <td>{{ item.value2 | currency : "USD" }}</td>
                    <td>
                        {{ item.growth2 | percent
                        }}<span *ngIf="item.growth2 > 0" style="color: green">&nbsp;&#9650;</span><span
                            *ngIf="item.growth2 < 0" style="color: red">&nbsp;&#9660;</span>
                    </td>
                    <td>{{ item.count2 | number}}</td>
                    <td>
                        {{ item.growth_percentage2 | percent
                        }}<span *ngIf="item.growth_percentage2 > 0" style="color: green">&nbsp;&#9650;</span><span
                            *ngIf="item.growth_percentage2 < 0" style="color: red">&nbsp;&#9660;</span>
                    </td>
                </tr>
                }
            </tbody>
        </table>
    </div>
    <div fxLayout="row" fxLayout.md="column" fxLayoutGap="1vw" *ngIf="!isEmptyObject(TablesData)" class="row">
        <div fxFlex class="section" fxLayout="column">
            <div fxLayout="row" fxLayout.md="column" fxGrow="0" fxLayoutGap="1vw">
                <span fxFlex class="title" fxGrow="0">{{'Written Premium' | translate }}</span>
                <select fxFlex fxGrow="0" [(ngModel)]="selectedWrittenPremiumFilter"
                    (ngModelChange)="filterWrittenPremium()">
                    @for (item of writtenPremiumOptions; track $index){
                    <option [value]="item.value">{{item.name}}</option>
                    }
                </select>
                <select fxFlex fxGrow="0" [(ngModel)]="selectedWrittenPremiumCarrierFilter"
                    (ngModelChange)="filterWrittenPremium()">
                    @for (item of carrierOptions; track $index){
                    <option [value]="item.value">{{item.name}}</option>
                    }
                </select>
                <div fxFlex class="details" fxLayoutAlign="end">
                    <span (click)="underConstruction()">{{'View Details' | translate }}</span>
                </div>
            </div>
            <div class="table-top" fxFlex>
                <table>
                    <thead>
                        <tr>
                            <th style="text-align: left;">Carrier</th>
                            <th style="width: fit-content;">WP</th>
                            <th style="width: fit-content;">Percent of total</th>
                        </tr>
                    </thead>
                    <tbody>
                        @for (item of TablesData?.written_premium; track $index) {
                        <tr>
                            <td>{{ item.company }}</td>
                            <td>{{ item.value | currency : "USD" }}</td>
                            <td>{{ item.percent_of_total | percent }}</td>
                        </tr>
                        }
                    </tbody>
                </table>
            </div>
            <div *ngIf="writtenPremiumChart" fxLayoutAlign="center end" fxFlex fxGrow="1" class="chart">
                <apx-chart #writtenPremiumChartSelector [series]="writtenPremiumChart.series"
                    [chart]="writtenPremiumChart.chart" [xaxis]="writtenPremiumChart.xaxis"
                    [labels]="writtenPremiumChart.labels" [dataLabels]="writtenPremiumChart.dataLabels"
                    [stroke]="writtenPremiumChart.stroke" [legend]="writtenPremiumChart.legend"
                    [fill]="writtenPremiumChart.fill" [tooltip]="writtenPremiumChart.tooltip"
                    [colors]="writtenPremiumChart.colors" [markers]="writtenPremiumChart.markers"
                    [plotOptions]="writtenPremiumChart.plotOptions"></apx-chart>
            </div>
            <div fxFlex class="chart-title" fxGrow="0">
                {{writePremiumChartTitle}}
            </div>
        </div>
        <div fxFlex class="section" fxLayout="column">
            <div fxLayout="row" fxLayout.md="column" fxFlex fxGrow="0" fxLayoutGap="1vw">
                <span fxFlex class="title" fxGrow="0">{{'Policies In Force' | translate }}</span>
                <select fxFlex fxGrow="0" [(ngModel)]="selectedPoliciesInForceFilter"
                    (ngModelChange)="filterPolicesInForce()">
                    @for (item of policiesInForceOptions; track $index){
                    <option [value]="item.value">{{item.name}}</option>
                    }
                </select>
                <select fxFlex fxGrow="0" [(ngModel)]="selectedPILCarrierFilter"
                    (ngModelChange)="filterPolicesInForce()">
                    @for (item of carrierOptions; track $index){
                    <option [value]="item.value">{{item.name}}</option>
                    }
                </select>
                <div fxFlex class="details" fxLayoutAlign="end">
                    <span (click)="underConstruction()">{{'View Details' | translate }}</span>
                </div>
            </div>
            <div class="table-top" fxFlex>
                <table>
                    <thead>
                        <tr>
                            <th style="text-align: left;">Carrier</th>
                            <th style="width: fit-content;">PIF</th>
                            <th style="width: fit-content;">Percent of Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        @for (item of TablesData?.policies_in_force; track $index) {
                        <tr>
                            <td>{{ item.company }}</td>
                            <td>{{ item.value | number}}</td>
                            <td>{{ item.percent_of_total | percent }}</td>
                        </tr>
                        }
                    </tbody>
                </table>
            </div>
            <div *ngIf="policiesInForceChart" fxLayoutAlign="center end" fxFlex fxGrow="1" class="chart">
                <apx-chart #policiesInForceSelector [series]="policiesInForceChart.series"
                    [chart]="policiesInForceChart.chart" [xaxis]="policiesInForceChart.xaxis"
                    [labels]="policiesInForceChart.labels" [dataLabels]="policiesInForceChart.dataLabels"
                    [stroke]="policiesInForceChart.stroke" [legend]="policiesInForceChart.legend"
                    [fill]="policiesInForceChart.fill" [tooltip]="policiesInForceChart.tooltip"
                    [colors]="policiesInForceChart.colors" [markers]="policiesInForceChart.markers"
                    [plotOptions]="policiesInForceChart.plotOptions"></apx-chart>
            </div>
            <div fxFlex class="chart-title" fxGrow="0">
                {{pilChartTitle}}
            </div>
        </div>
    </div>
    <div fxLayout="row" fxLayout.md="column" fxLayoutGap="1vw" *ngIf="!isEmptyObject(TablesData)" class="row">
        <div fxFlex class="section" fxLayout="column">
            <div fxLayout="row" fxLayout.md="column" fxGrow="0" fxLayoutGap="1vw">
                <span fxFlex class="title" fxGrow="0">{{'Growth' | translate }}</span>
                <select fxFlex fxGrow="0" [(ngModel)]="selectedGrowthFilter" (ngModelChange)="filterGrowth()">
                    @for (item of growthOptions; track $index){
                    <option [value]="item.value">{{item.name}}</option>
                    }
                </select>
                <div fxFlex class="details" fxLayoutAlign="end">
                    <span (click)="underConstruction()">{{'View Details' | translate }}</span>
                </div>
            </div>
            <div class="table-top" fxFlex>
                <table>
                    <thead>
                        <tr>
                            <th style="text-align: left;">{{'Line of Business' | translate}}</th>
                            <th>{{'Prior Year WP' | translate}}</th>
                            <th>{{'YoY Change' | translate}}</th>
                            <th>{{'YoY % Change' | translate}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        @for (row of TablesData.growth; track
                        $index) {
                        <tr [hidden]="!growthTableExpanded && row.expandable" (click)="expandGrowth(row)"
                            [ngStyle]="{'cursor': row.triggerExpand ? 'pointer': 'default'}">
                            <td [ngStyle]="{'padding-left': row.expandable ? '1vw': ''}">{{ row.label | translate}}
                                <mat-icon style="vertical-align: text-top;font-size: 13pt;"
                                    *ngIf="row.triggerExpand">{{growthTableExpanded?
                                    'keyboard_arrow_up':'keyboard_arrow_down'}}</mat-icon>
                            </td>
                            <td>{{ row.priorYear | currency : "USD" }}</td>
                            <td>
                                {{ row.yoYChange | currency : "USD"
                                }}<span *ngIf="row.yoYChange > 0" style="color: green">&nbsp;&#9650;</span><span
                                    *ngIf="row.yoYChange < 0" style="color: red">&nbsp;&#9660;</span>
                            </td>
                            <td>
                                {{ row.yoYChangePercentage | percent
                                }}<span *ngIf="row.yoYChangePercentage > 0"
                                    style="color: green">&nbsp;&#9650;</span><span *ngIf="row.yoYChangePercentage < 0"
                                    style="color: red">&nbsp;&#9660;</span>
                            </td>
                        </tr>
                        }
                    </tbody>
                </table>
            </div>
            <div *ngIf="growthChart" fxLayoutAlign="center end" fxFlex fxGrow="1" class="chart">
                <apx-chart #growthChartSelector [series]="growthChart.series" [chart]="growthChart.chart"
                    [xaxis]="growthChart.xaxis" [yaxis]="growthChart.yaxis" [dataLabels]="growthChart.dataLabels"
                    [stroke]="growthChart.stroke" [legend]="growthChart.legend" [fill]="growthChart.fill"
                    [tooltip]="growthChart.tooltip" [colors]="growthChart.colors"></apx-chart>
            </div>
        </div>
        <div fxFlex class="section" fxLayout="column">
            <div fxLayout="row" fxLayout.md="column" fxFlex fxGrow="0" fxLayoutGap="1vw">
                <span fxFlex class="title" fxGrow="0"> {{'EZLynx Quotes to New Business Policies' | translate }}</span>
                <select fxFlex fxGrow="0" [(ngModel)]="selectedQuoteFilter" (ngModelChange)="filterQuotes($event)">
                    @for (item of quotesOptions; track $index){
                    <option [value]="item.value">{{item.name}}</option>
                    }
                </select>
                <div fxFlex class="details" fxLayoutAlign="end">
                    <span (click)="underConstruction()">{{'View Details' | translate }}</span>
                </div>
            </div>
            <div class="table-top" fxFlex>
                <table>
                    <tbody>
                        <tr>
                            <td>{{'Quote Count' | translate}}</td>
                            <td>{{TablesData.quotes.quoteCount}}</td>
                        </tr>
                        <tr>
                            <td>{{'New Business Count' | translate}}</td>
                            <td>{{TablesData.quotes.newBusinessCount}}</td>
                        </tr>
                        <tr>
                            <td>{{'Close Ratio' | translate}}</td>
                            <td>{{TablesData.quotes.closeRatio * 100}}%</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div *ngIf="quotesChart" fxLayoutAlign="center end" fxFlex fxGrow="1" class="chart">
                <apx-chart #quotesChartSelector [series]="quotesChart.series" [chart]="quotesChart.chart"
                    [xaxis]="quotesChart.xaxis" [dataLabels]="quotesChart.dataLabels" [stroke]="quotesChart.stroke"
                    [legend]="quotesChart.legend" [fill]="quotesChart.fill" [tooltip]="quotesChart.tooltip"
                    [colors]="quotesChart.colors"></apx-chart>
            </div>
        </div>
    </div>
</ng-container>

<div class="filters-backdrop" *ngIf="showFilters">
    <div class="more-filters" fxLayout="column" fxLayoutGap="3vh">
        <div class="title" fxGrow="0" fxFlex>Selected Filters</div>
        <div fxFlex fxGrow="1" class="chips">
            <mat-chip-set fxLayout="row wrap" fxLayoutGap="0.5vw">
                @for (item of chips; track $index) {
                <mat-chip fxFlex fxGrow="0">
                    <mat-icon leadingIcon class="remove-filter" (click)="removeFilter(item)">close</mat-icon>
                    {{item.name}}
                </mat-chip>
                }
            </mat-chip-set>
        </div>
        <div fxFlex fxLayoutAlign="end" fxGrow="0">
            <button color="primary" mat-raised-button (click)="toggleMoreFilter()">{{'Close' | translate}}</button>
        </div>
    </div>
</div>