import { Component, OnInit, Inject } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, ValidatorFn } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { LoaderService } from "src/app/shared";
import { PGIService } from "src/app/shared/services/pgi.service";

interface DialogData {
    loggedInUserType: number;
    filters: any;
    activeFilters: any;
    isAdmin: boolean;
}

@Component({
    selector: 'app-charts-filter-dialog',
    templateUrl: './charts-filter-dialog.component.html',
    styleUrls: ['./charts-filter-dialog.component.scss'],
})
export class ChartsFilterDialogComponent implements OnInit {

    public date: Date = new Date();
    public selected: any = {
        agency: [],
        carrier: [],
        state: [],
        lob: [],
        productline: [],
        agent: [],
    };
    public allSelected: any = {
        agency: true,
        carrier: true,
        state: true,
        lob: true,
        productline: true,
        agent: true,
    }
    public dateRangeControl = new FormControl('');
    public startDate = new FormControl('', { validators: this.dateRangeValidator() });
    public endDate = new FormControl('', { validators: this.dateRangeValidator() });

    private allAgencies: any = [];

    constructor(private translate: TranslateService, public dialogRef: MatDialogRef<ChartsFilterDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData, private pgiService: PGIService, private loader: LoaderService) {
        this.selected = {
            agency: this.data.activeFilters.agency || '',
            agent: this.data.activeFilters.agent || '',
            carrier: this.data.activeFilters.carrier || '',
            state: this.data.activeFilters.state || '',
            productline: this.data.activeFilters.productline || '',
            lob: this.data.activeFilters.lob || '',
        };

        this.startDate.setValue(this.data.activeFilters.startDate || '');
        this.endDate.setValue(this.data.activeFilters.endDate || '');
        this.dateRangeControl.setValue(this.data.activeFilters.dateType || '');

        this.data.filters.agency = this.data.filters.agency.filter(i => i != 'All Agencies');
        this.allAgencies = this.data.filters.agency;
        this.data.filters.agent = this.data.filters.agent.filter(i => i != 'All Agents');
        this.data.filters.carrier = this.data.filters.carrier.filter(i => i != 'All Carriers');
        this.data.filters.state = this.data.filters.state.filter(i => i != 'All States');
        this.data.filters.productline = this.data.filters.productline.filter(i => i != 'All Product Lines');
        this.data.filters.lob = this.data.filters.lob.filter(i => i != 'All LOBs');

        if (this.data.activeFilters.agency.includes('All Agencies')) {
            this.selected.agency = [...this.data.filters.agency, 'All Agencies'];
        }

        if (this.data.activeFilters.agent.includes('All Agents')) {
            this.selected.agent = [...this.data.filters.agent, 'All Agents'];
        }

        if (this.data.activeFilters.carrier.includes('All Carriers')) {
            this.selected.carrier = [...this.data.filters.carrier, 'All Carriers'];
        }

        if (this.data.activeFilters.state.includes('All States')) {
            this.selected.state = [...this.data.filters.state, 'All States'];
        }

        if (this.data.activeFilters.productline.includes('All Product Lines')) {
            this.selected.productline = [...this.data.filters.productline, 'All Product Lines'];
        }

        if (this.data.activeFilters.lob.includes('All LOBs')) {
            this.selected.lob = [...this.data.filters.lob, 'All LOBs'];
        }
    }

    ngOnInit(): void {
    }

    private async getFilters(updateAgencies: boolean = false) {
        this.loader.display(true);
        let agency = this.selected.agency.filter(i => i != 'All Agencies').length == this.data.filters.agency.length ? ['All Agencies'] : this.selected.agency.filter(i => i != 'All Agencies');
        let lob = this.selected.lob.filter(i => i != 'All LOBs').length == this.data.filters.lob.length ? ['All LOBs'] : this.selected.lob.filter(i => i != 'All LOBs');
        let state = this.selected.state.filter(i => i != 'All States').length == this.data.filters.state.length ? ['All States'] : this.selected.state.filter(i => i != 'All States');
        let productline = this.selected.productline.filter(i => i != 'All Product Lines').length == this.data.filters.productline.length ? ['All Product Lines'] : this.selected.productline.filter(i => i != 'All Product Lines');
        let agent = this.selected.agent.filter(i => i != 'All Agents').length == this.data.filters.agent.length ? ['All Agents'] : this.selected.agent.filter(i => i != 'All Agents');
        let carrier = this.selected.carrier.filter(i => i != 'All Carriers').length == this.data.filters.carrier.length ? ['All Carriers'] : this.selected.carrier.filter(i => i != 'All Carriers');

        if (agency.length == 0) {
            agency = undefined;
        }

        // if (agent.length && agency.length != 0) {
        //     agent = [];
        // }

        let pgiFiltersData = await this.pgiService.getPGIOverviewFilter(agency, lob, state, productline, agent, carrier).catch(() => {
            this.loader.display(false);
        });

        if (pgiFiltersData && pgiFiltersData.filters) {
            let agencies: any = updateAgencies ? pgiFiltersData.filters.Agency.concat(this.selected.agency).filter((item, index, array) => array.indexOf(item) === index).filter(i => i != 'All Agencies') : this.allAgencies;
            let agents: any = pgiFiltersData.filters.Agent.concat(this.selected.agent).filter((item, index, array) => array.indexOf(item) === index).filter(i => i != 'All Agents');
            let states: any = pgiFiltersData.filters.AgencyState.concat(this.selected.state).filter((item, index, array) => array.indexOf(item) === index).filter(i => i != 'All States');
            let productLines: any = pgiFiltersData.filters.ProductLine.concat(this.selected.productline).filter((item, index, array) => array.indexOf(item) === index).filter(i => i != 'All Product Lines');
            let lobs: any = pgiFiltersData.filters.LOB.concat(this.selected.lob).filter((item, index, array) => array.indexOf(item) === index).filter(i => i != 'All LOBs');
            let carriers: any = pgiFiltersData.filters.CarrierName.concat(this.selected.carrier).filter((item, index, array) => array.indexOf(item) === index).filter(i => i != 'All Carriers');

            this.data.filters = {
                agency: agencies,
                lob: lobs,
                state: states,
                productline: productLines,
                agent: agents,
                carrier: carriers,
            };
        }
        this.loader.display(false);
    }

    dateRangeValidator(): ValidatorFn {
        return (formGroup: AbstractControl): { [key: string]: any } | null => {
            const start = formGroup.get('startDate')?.value;
            const end = formGroup.get('endDate')?.value;
            return start && end && start > end ? { 'invalidDateRange': true } : null;
        };
    }

    applyFilters() {
        this.dialogRef.close({
            agency: this.selected.agency.includes('All Agencies') ? ['All Agencies'] : this.selected.agency,
            agent: this.selected.agent.includes('All Agents') ? ['All Agents'] : this.selected.agent,
            carrier: this.selected.carrier.includes('All Carriers') ? ['All Carriers'] : this.selected.carrier,
            state: this.selected.state.includes('All States') ? ['All States'] : this.selected.state,
            productline: this.selected.productline.includes('All Product Lines') ? ['All Product Lines'] : this.selected.productline,
            lob: this.selected.lob.includes('All LOBs') ? ['All LOBs'] : this.selected.lob,
            dateType: this.dateRangeControl.value,
            startDate: this.startDate.value,
            endDate: this.endDate.value,
        });
    }

    onSelectionChange(type: string, value: string, allValue: string) {
        if (!this.selected[type].includes(value) && this.selected[type].includes(allValue)) {
            this.selected[type] = this.selected[type].filter(i => i != allValue);
        }
        else if (this.selected[type].length == this.data.filters[type].length && !this.selected[type].includes(allValue)) {
            this.selected[type] = [allValue, ...this.data.filters[type]];
            this.allSelected[type] = true;
        }

        this.getFilters();
    }

    toggleSelectAll(type: string, valueName: string) {
        if (!this.allSelected[type]) {
            this.selected[type] = [valueName, ...this.data.filters[type]];
        }
        else {
            this.selected[type] = [];
        }

        this.allSelected[type] = !this.allSelected[type];

        this.getFilters();
    }
}